<template>
  <el-container class="chat-page">
    <el-aside width="260px" class="chat-list-box">
      <div class="chat-list-header">
        <el-input class="search-text" placeholder="搜索" v-model="searchText">
          <i class="el-icon-search el-input__icon" slot="prefix"> </i>
        </el-input>
      </div>
      <div class="chat-list-loadding"  v-if="loading" v-loading="true" element-loading-text="消息接收中..."
           element-loading-spinner="el-icon-loading" element-loading-background="#eee">
        <div class="chat-loading-box"></div>
      </div>
      <el-scrollbar class="chat-list-items">
        <div v-for="(chat,index) in chatStore.chats" :key="index">
          <chat-item v-show="chat.showName.startsWith(searchText)" :chat="chat" :index="index"
                     @click.native="onActiveItem(index)" @delete="onDelItem(index)" @top="onTop(index)"
                     :active="chat === chatStore.activeChat"></chat-item>
        </div>
      </el-scrollbar>
    </el-aside>
    <el-container class="chat-box">
      <chat-box v-if="chatStore.activeChat" :chat="chatStore.activeChat"></chat-box>
    </el-container>
  </el-container>
</template>

<script>
import ChatItem from "../components/chat/ChatItem.vue";
import ChatBox from "../components/chat/ChatBox.vue";

export default {
  name: "chat",
  components: {
    ChatItem,
    ChatBox
  },
  data() {
    return {
      searchText: "",
      messageContent: "",
      group: {},
      groupMembers: []
    }
  },
  methods: {
    onActiveItem(index) {
      this.$store.commit("activeChat", index);
    },
    onDelItem(index) {
      this.$store.commit("removeChat", index);
    },
    onTop(chatIdx) {
      this.$store.commit("moveTop", chatIdx);
    },
  },
  computed: {
    chatStore() {
      return this.$store.state.chatStore;
    },
    loading(){
      return this.chatStore.loadingGroupMsg || this.chatStore.loadingPrivateMsg
    }
  }
}
</script>

<style lang="scss">
.chat-page {
  .chat-list-box {
    display: flex;
    flex-direction: column;
    border: none;
    background: white;
    width: 3rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-right: 16px;
    transition: all 0.3s ease;
    overflow: hidden;

    .chat-list-header {
      padding: 5px 12px;
      line-height: 66px;
      background-color: #f5f7fa;
      position: relative;
      border-radius: 8px 8px 0 0;
      
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 5%;
        width: 90%;
        height: 1px;
        background: rgba(0, 0, 0, 0.05);
      }

      .el-input__inner {
        border-radius: 20px !important;
        background-color: white;
        transition: all 0.3s ease;
        border: 1px solid #e6e6e6;
        
        &:focus {
          box-shadow: 0 0 0 2px rgba(220, 223, 230, 0.3);
        }
      }
    }

    .chat-list-loadding {
      height: 50px;
      background-color: #f9f9f9;
      border-radius: 4px;
      margin: 8px;
      transition: all 0.3s ease;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
      overflow: hidden;
      position: relative;

      .chat-loading-box {
        height: 100%;
      }
    }

    .chat-list-items {
      flex: 1;
      background: white;
      margin: 5px;
      border-radius: 0 0 8px 8px;
      transition: all 0.3s ease;
      padding: 5px;
    }
  }
  
  .chat-box {
    border: none;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
    position: relative;
    background-color: white;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #409EFF;
      z-index: 1;
    }
  }
}

// 滚动条样式
.el-scrollbar {
  transition: all 0.3s ease;
  
  &:hover {
    .el-scrollbar__thumb {
      opacity: 0.8;
    }
  }
}

.el-scrollbar__thumb {
  background-color: #DCDFE6;
  border-radius: 20px;
  opacity: 0.4;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #C0C4CC;
    opacity: 1;
  }
}

// 输入框动效
.el-textarea__inner {
  border-radius: 8px !important;
  transition: all 0.3s ease;
  border: 1px solid #DCDFE6;
  
  &:focus {
    box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.15);
    border-color: #409EFF;
  }
}

// 按钮效果
.el-button--primary {
  background-color: #409EFF;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 0 2px 6px rgba(64, 158, 255, 0.2);
  transition: all 0.3s ease;
  font-weight: 500;
  
  &:hover {
    background-color: #66b1ff;
    box-shadow: 0 4px 12px rgba(64, 158, 255, 0.3);
    transform: translateY(-2px);
  }
  
  &:active {
    transform: translateY(-1px);
  }
}

// 优化消息展示样式
.message-item {
  transition: all 0.3s ease;
  
  &.message-mine .message-bubble {
    background-color: #ecf5ff;
    box-shadow: 0 2px 8px rgba(64, 158, 255, 0.1);
  }
  
  &:not(.message-mine) .message-bubble {
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
}

// 优化提示样式
.el-message {
  border-radius: 8px;
  padding: 10px 16px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  
  &.el-message--success {
    background-color: #f0f9eb;
    border-color: #e1f3d8;
    
    .el-message__content, .el-icon-success {
      color: #67c23a;
    }
  }
  
  &.el-message--warning {
    background-color: #fdf6ec;
    border-color: #faecd8;
    
    .el-message__content, .el-icon-warning {
      color: #e6a23c;
    }
  }
  
  &.el-message--error {
    background-color: #fef0f0;
    border-color: #fde2e2;
    
    .el-message__content, .el-icon-error {
      color: #f56c6c;
    }
  }
}
</style>